import { Box, Stack, Typography } from "@mui/material"
import PropTypes from "prop-types";
import { ButtonAndMenu } from "./ButtonAndMenu"
import { updateProperty } from "../../api/Properties";
import { ApiErrorHandler } from "../../api/ApiErrorHandler";
import { PROPERTY_STATUSES_UNDER_CONSIDERATION, PROPERTY_STATUSES_CONTRACT, PROPERTY_STATUSES_SETTLEMENT, getPageByStatus } from "../../utils/Constants";
import { DownArrowIcon } from "../atoms/DownArrowIcon";
import { isMobile } from "react-device-detect";
import { PropertyEditInfo } from "../organisms/PropertyEditInfo";


export const PropertyEditStatus = (props) => {

  const showEditInfo = props.showEditInfo;
  const property = props.property;
  const choices = props.choices;
  const pageChangedAction = props.pageChangedAction;
  const apiErrorAction = props.apiErrorAction;

  const menus = choices.statuses?.map((item) => {
    const menu = {
      label: item.label,
      disabled: property.status + 1 < item.value,
      onClick: () => {
        if (property.status + 1 < item.value) {
          return;
        }

        if (item.value <= property.status) {
          pageChangedAction(getPageByStatus(item.value));
          return;
        }
        const data = {
          status: item.value,
        };
        updateProperty(property.id, data)
          .then(() => {
            pageChangedAction(getPageByStatus(item.value));
          })
          .catch((error) => {
            apiErrorAction("ステータスの更新に失敗しました。");
            ApiErrorHandler(error);
          });
      },
    };
    return menu;
  });

  const getStatusDisplay = () => {
    let display = "";
    choices?.statuses?.forEach((item) => {
      if (item.value == property?.status) {
        display = item.label;
      }
    });
    return display;
  };
  const getStatusColor = (status) => {
    if (PROPERTY_STATUSES_UNDER_CONSIDERATION.includes(status)) {
      return "brown";
    }
    if (PROPERTY_STATUSES_CONTRACT.includes(status)) {
      return "orange";
    }
    if (PROPERTY_STATUSES_SETTLEMENT.includes(status)) {
      return "darkGreen";
    }
    return undefined;
  };

  const buttonAndMenuSx = isMobile ? { minWidth: "95px", flexGrow: 1, display: "flex", justifyContent: "flex-end" } : { minWidth: "95px" }

  return (
    <Box sx={{ p: 0, m: 0, flexGrow: 1, minWidth: "0", ...props.sx }}>
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        sx={{ minWidth: "0" }}
        justifyContent={isMobile ? "space-between" : "flex-start"}
      >
        <Stack
          alignItems={isMobile ? undefined : "center"}
          direction={isMobile ? "column" : "row"}
          sx={{
            pr: 1,
            whiteSpace: "nowrap",
            minWidth: "0"
          }}
        >
          <Box
            component={isMobile ? "h6" : "h5"}
            sx={{
              m: 0,
              p: 0,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              fontSize: "1rem",
            }}
          >
            {property.name}
          </Box>
          <Box
            component={isMobile ? "h6" : "h5"}
            sx={{
              m: 0,
              p: 0,
              fontSize: "0.9rem",
            }}
          >
            {`【${property.area_code_display ?? ""}/${property.grade_display ?? ""}】`}
          </Box>
        </Stack>
        <Stack direction="row">
          {showEditInfo &&
            <PropertyEditInfo
              cost_price={property?.businessbalance?.cost_price}
              gross_profit_per_building={property?.businessbalance?.gross_profit_per_building}
            />
          }
          <ButtonAndMenu
            sx={buttonAndMenuSx}
            color={getStatusColor(property?.status)}
            variant={isMobile ? "outlined" : "text"}
            buttonSx={isMobile ? { borderRadius: 5 } : {}}
            buttonName={
              <>
                {isMobile
                  ?
                  <Stack direction="row" alignItems="center">
                    <Typography sx={{ pr: 1, fontWeight: "bold" }}>
                      {getStatusDisplay()}
                    </Typography>
                    <DownArrowIcon color={getStatusColor(property?.status)} sx={{ fontSize: "0.8rem" }} />
                  </Stack>
                  :
                  <Box sx={{ border: 1, borderRadius: 5, px: 1, color: getStatusColor(property?.status) }}>
                    {getStatusDisplay()}
                  </Box>
                }
              </>
            }
            endIcon={isMobile ? undefined : <DownArrowIcon />}
            menus={menus}
          />
        </Stack>
      </Stack>
    </Box>
  )
}

PropertyEditStatus.propTypes = {
  property: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  pageChangedAction: PropTypes.func,
  apiErrorAction: PropTypes.func,
  sx: PropTypes.object,
  showEditInfo: PropTypes.bool,
};


PropertyEditStatus.defaultProps = {
  sx: {},
  showEditInfo: false,
};

