import React from "react";
import { getPropertyShare, propertyShareBulkShare } from "../../../api/Admin";
import { AdminLayout } from "../../templates/AdminLayout";
import { ApiErrorHandler } from "../../../api/ApiErrorHandler";
import { PropertyBulkShareInputs } from "../../molecules/PropertyBulkShareInputs";
import { useForm } from "react-hook-form";
import { Stack, Box, Container } from "@mui/material";
import { FormControl } from "../../atoms/FormControl";
import { Button } from "../../atoms/Button";
import { Snackbar } from "../../atoms/Snackbar";

export const PropertyShare = () => {
  const [user, setUser] = React.useState({});
  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [choices, setChoices] = React.useState({});
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("error");
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const canSubmit = () => {
    return !isSubmitting;
  };

  const {
    control,
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    criteriaMode: "all",
    defaultValues: "",
  });

  const submitAction = (data) => {
    console.log(data)
    if (data.owner_user_id === data.share_user_id) {
      const sameValueError = {
        type: "custom",
        message: "同一ユーザーです"
      }
      setError("owner_user_id", sameValueError);
      setError("share_user_id", sameValueError);
      return
    }
    setIsSubmitting(true);
    propertyShareBulkShare(data)
      .then(() => {
        setMessage("保存しました");
        setSeverity("success");
      })
      .catch((error) => {
        ApiErrorHandler(error);
        setMessage("保存に失敗しました");
        setSeverity("error");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  React.useEffect(() => {
    setLoadingOpen(true);
    getPropertyShare()
      .then((response) => {
        console.log(response.data)
        setUser(response.data.user)
        setChoices(response.data.choices)
      })
      .catch((error) => {
        ApiErrorHandler(error);
      })
      .finally(() => {
        setLoadingOpen(false);
      });
  }, []);


  return (
    <AdminLayout
      pageTitle="物件一括共有"
      user={user}
      loadingOpen={loadingOpen}
    >
      <Snackbar
        closeAction={() => {
          setMessage("");
        }}
        message={message}
        severity={severity}
      />
      <Stack
        alignItems="center"
        sx={{ minHeight: "100vh", marginTop: 15 }}
      >
        <Box component="h1">物件一括共有</Box>
        <Container maxWidth="sm">
          <Box sx={{ marginTop: 3 }}>
            <FormControl
              error={errors ? true : false}
              fullWidth={true}
            >
              <PropertyBulkShareInputs
                errors={errors}
                register={register}
                control={control}
                choices={choices}
                setValue={setValue}
              />
            </FormControl>
          </Box>
        </Container>
        <Button
          color="green"
          type="submit"
          onClick={handleSubmit(submitAction)}
          disabled={!canSubmit()}
        >
          共有
        </Button>
      </Stack>
    </AdminLayout>
  );
};
