import * as React from "react";
import PropTypes from "prop-types";
import { Dialog } from "../molecules/Dialog";
import { Button } from "../atoms/Button";
import { Snackbar } from "../atoms/Snackbar";
import { DeleteIcon } from "../atoms/DeleteIcon";
import { deleteHasProperties } from "../../api/Properties";
import { isMobile } from "react-device-detect";

export const DeletePropertyDialog = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("error");
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const targetIds = props.targetIds

  React.useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const handleClose = () => {
    props.handleClose();
  };
  const submitAction = () => {
    setIsSubmitting(true);
    const data = {
      ids: targetIds,
    }
    deleteHasProperties(data)
      .then((response) => {
        props.dataReload(response.data)
        setMessage("削除しました");
        setSeverity("success");
        handleClose();
      })
      .catch(() => {
        setMessage("削除に失敗しました");
        setSeverity("error");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Snackbar
        closeAction={() => {
          setMessage("");
        }}
        message={message}
        severity={severity}
      />
      <Dialog
        isOpen={isOpen}
        title={
          <>
            <DeleteIcon color="black" sx={{mr: 1}}/>
            削除
          </>
        }
        handleClose={handleClose}
        content="選択した物件を削除しますか？"
        actions={
          <>
            <Button color={isMobile ? "gray" : "white"} onClick={handleClose} disabled={isSubmitting} fullSize={isMobile}>
              キャンセル
            </Button>
            <Button color="error" onClick={submitAction} disabled={isSubmitting} fullSize={isMobile}>
              削除
            </Button>
          </>
        }
      />
    </>
  );
};

DeletePropertyDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  dataReload: PropTypes.func,
  targetIds: PropTypes.array.isRequired,
}
DeletePropertyDialog.defaultProps= {
  dataReload: () => { },
  targetIds: [],
};