// ユーザー関連

import { PROPERTY_STATUSES } from "./Constants";

export const isOwner = (property, user) => {
  const is_owner = property?.owner_user?.id == user?.id;
  return is_owner;
};

export const getPrppetyShare = (property, user) => {
  // 権限
  const shared =
    property.propertyshare_set &&
    property.propertyshare_set.find((v) => v.shared_user === user.id);

  return shared;
};

/**
 * 物件を編集可能か
 * @param {object} property
 * @returns {boolean}
 */
export const isPropertyEditable = (property) => {
  return property?.is_editable ?? false
};

/**
 * 物件を編集可能な権限を持っているか
 * @param {object} property
 * @returns {boolean}
 */
export const hasPropertyEditPermission = (property) => {
  return property?.has_edit_permission ?? false
};

/**
 * 企画依頼の権限があるか
 * @param {object} property
 * @returns {boolean}
 */
export const hasPlanningRequestPermission = (property) => {
  if (property.status < PROPERTY_STATUSES.CONTRACT_COMPLETE) {
    return false
  }
  return property?.is_editable_planning_request ?? false
};