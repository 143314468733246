import * as React from "react";
import { Controller } from "react-hook-form";
import { FormHelperText, RadioGroup as MUIRadioGroup } from "@mui/material";
import PropTypes from "prop-types";
import { FormControl } from "./FormControl";
import { FormLabel } from "./FormLabel";

export const RadioGroup = (props) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      rules={props.rules}
      render={({ field, fieldState }) => (
        <FormControl error={fieldState.error ? true : false}>
          <FormLabel sx={props.labelSx}>{props.label}</FormLabel>
          <MUIRadioGroup
            aria-labelledby="radio-buttons-group-label"
            {...field}
            value={field.value ? field.value : ""}
          >
            {props.children}
          </MUIRadioGroup>

          {fieldState.error?.message &&
            <FormHelperText>
              {fieldState.error?.message}
            </FormHelperText>
          }
        </FormControl>
      )}
    />
  );
};


RadioGroup.propTypes = {
  label: PropTypes.any,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  rules: PropTypes.object.isRequired,
  children: PropTypes.any,
  labelSx: PropTypes.object,
}