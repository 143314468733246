export const defaultViewport = "width=device-width, initial-scale=1"
export const mobileViewport = "width=device-width, initial-scale=0.2"

export const PROPERTY_STATUSES = {
  UNDER_CONSIDERATION: 1,
  CONTRACT: 2,
  CONTRACT_COMPLETE: 3,
  SETTLEMENT: 4,
  SETTLEMENT_COMPLETE: 5,
};

export const PROPERTY_STATUSES_UNDER_CONSIDERATION = [
  PROPERTY_STATUSES.UNDER_CONSIDERATION,
]
export const PROPERTY_STATUSES_CONTRACT = [
  PROPERTY_STATUSES.CONTRACT,
  PROPERTY_STATUSES.CONTRACT_COMPLETE,
]
export const PROPERTY_STATUSES_SETTLEMENT = [
  PROPERTY_STATUSES.SETTLEMENT,
  PROPERTY_STATUSES.SETTLEMENT_COMPLETE,
]
export const PAGES = {
  CONSIDERATION_INFORMATION: 1,
  CONTRACT_INFORMATION: 2,
  SETTLEMENT_INFORMATION: 3,
  PLANNING_REQUEST: 4,
}
export const PAGE_PROPERTY_STATUS_RELATION = {
  [PAGES.CONSIDERATION_INFORMATION]: PROPERTY_STATUSES_UNDER_CONSIDERATION,
  [PAGES.CONTRACT_INFORMATION]: PROPERTY_STATUSES_CONTRACT,
  [PAGES.SETTLEMENT_INFORMATION]: PROPERTY_STATUSES_SETTLEMENT,
}

export const getPageByStatus = (status) => {
  let result = null
  Object.keys(PAGE_PROPERTY_STATUS_RELATION).forEach((key) => {
    const statuses = PAGE_PROPERTY_STATUS_RELATION[key]
    if (statuses.includes(status)) {
      result = Number(key)
    }
  })
  return result
}

export const CONTRACT_AMOUNT_DETAIL_TYPE = {
  EARNEST: 1,
  INTERMEDIARY_CHARGE_ON_CONTRACT: 2,
  REVENUE_STAMP_CASH_ON_HAND_SENTENCE: 3,
  INTERMEDIATE_MONEY: 4,
  REMAINING_PRICE: 5,
  REMAINING_PRICE_INTERMEDIARY_CHARGE: 6,
  BUSINESS_CONSIGNMENT_FEE: 7,
  RESERVATION_MONEY: 8,
};

export const SETTLEMENT_CONFIRMATION_CONTRACT_TERM_TYPE = {
  FIXED_MEASUREMENT_DRAWING_ISSUE: 1,
  FIXED_MEASUREMENT_DRAWING_ISSUE_OWNER_ALL_MEMBERS_EXISTS: 2,
  FIXED_MEASUREMENT_DRAWING_ISSUE_EXISTS_THIRD_PARTY_INHERITED_MESSAGE: 3,
  FIXED_MEASUREMENT_DRAWING_ISSUE_EXISTS_DATE_DESCRIBED: 4,
  FIXED_MEASUREMENT_DRAWING_ISSUE_PRIOR_NARROW_DISCUSSED: 5,
  FIXED_MEASUREMENT_DRAWING_ISSUE_AREA_IF_MATCHES: 6,
  TRANSBOUNDARY_DECONTAMINATION: 7,
  PRIVATE_ROAD_AGREEMENT_DOCUMENT: 8,
  PRIVATE_ROAD_AGREEMENT_DOCUMENT_TARGET: 9,
  PRIVATE_ROAD_AGREEMENT_DOCUMENT_OWNER_ALL_MEMBERS_EXISTS: 10,
  PRIVATE_ROAD_AGREEMENT_DOCUMENT_EXISTS_THIRD_PARTY_INHERITED_MESSAGE: 11,
  PRIVATE_ROAD_AGREEMENT_DOCUMENT_EXISTS_DATE_DESCRIBED: 12,
  PLOT_CORRECTION_REGISTRATION: 13,
  REGISTRATION_OF_SUBDIVISION: 14,
  PASSING_CRITERIA: 15,
  DISMANTLING_BEFORE_CLEARING: 16,
  OTHER_CHARACTER_TRADE: 17,
  LAND_CATEGORY_CHANGE_REGISTRATION: 18,
  CONTRACT_STYLE: 19,
  PLAN_CRITERIA: 20,
  VALID_AREA_CRITERIA: 21,
  FRONTAGE_ALLOCATION_CRITERIA: 22,
  DEVELOPMENT_POSITION_SPECIFIED_CONSTRUCTION: 23,
  ABOLITION_NOTIFICATION: 24,
  PRODUCTIVE_GREEN: 25,
  VIOLATION_OF_CIVIL_LAW: 26,
  BUILDING_CONFIRMATION: 27,
  CONTRACT_WHEN_FROM_CONDITION_CHANGE_EXISTS: 28,
  CLEARING_WHEN_MORTGAGE_ERASURE_UNNECESSARY_IS: 29,
  HAS_ISSUED_TRANSFER_AGREEMENT_FORM: 30,
  IS_SIGNING_LEASE_CONTRACT: 31,
  IS_HEARING_SHEET_CREATING_DONE: 32,
  NOTE1: 33,
  NOTE2: 34,
};

export const SETTLEMENT_CONTACTS_DENOMINATION_TYPE = {
  SELLER: 1,
  BROKER: 2,
  BUSINESS_COMMISSION: 3,
  JUDICIAL_SCRIVENER: 4,
  LANDOWNER: 5,
};