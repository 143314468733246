import { Dialog } from "./Dialog";
import PropTypes from "prop-types";
import * as React from "react";
import { Button } from "../atoms/Button";
import { isMobile } from "react-device-detect";
import { PropertyTableSearchForms } from "./PropertyTableSearchForms";

export const PropertyTableSearchDialog = (props) => {

  const [isOpen, setIsOpen] = React.useState(false);
  const [searchValues, setSearchValues] = React.useState({});

  const {handleClose, doSearch, nowSearchWords} = props
  const [isFormReset, setIsFormReset] = React.useState(false)

  React.useEffect(() => {
    setSearchValues(nowSearchWords)
  }, [nowSearchWords])

  React.useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const handleSearch = () => {
    var clear = Object.keys(searchValues).length === 0
    Object.keys(searchValues).forEach((key) => {
      if (searchValues[key] || searchValues[key] === 0) {
        clear = false
        return
      }
    })
    doSearch(searchValues, clear)
    handleClose()
  }

  return (
    <>
      <Dialog
        fullScreen={false}
        isOpen={isOpen}
        content={
          <PropertyTableSearchForms
            choices={props.choices}
            doSearch={(obj) => {
              let newValues = {...searchValues, ...obj}
              let newSearchValues = {}
              Object.keys(newValues).forEach((key) => {
                if (newValues[key]) {
                  newSearchValues[key] = newValues[key]
                }
              })
              setSearchValues({ ...newSearchValues })
            }}
            isReset={isFormReset}
            defaultValues={nowSearchWords}
            resetedAction={() => setIsFormReset(false)}
          />
        }
        actions={
          <>
            <Button
              color="white"
              fullSize={isMobile}
              onClick={() => {
                setIsFormReset(true)
                setSearchValues({})
              }}
            >
              クリア
            </Button>
            <Button color="white" fullSize={isMobile} onClick={handleSearch}>
              結果を表示
            </Button>
          </>
        }
      />
    </>
  )
}

PropertyTableSearchDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  doSearch: PropTypes.func.isRequired,
  choices: PropTypes.object.isRequired,
  nowSearchWords: PropTypes.object.isRequired,
};

PropertyTableSearchDialog.defaultProps = {
  isOpen: false,
}